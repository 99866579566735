import React from 'react';
import PropTypes from "prop-types";

import ContactDetailItem from './ContactDetailItem';

const ContactDetails = ({ container = "container mx-auto px-2 md:px-4", detailItems = []}) => (
  <div className={`${container} mb-16 md:mb-24 flex flex-wrap`}>
    {detailItems.map(detailItem => (
      <div className="w-full md:w-1/3 mb-10 md:mb-0" key={detailItem.title} >
        <ContactDetailItem
          title={detailItem.title} 
          text={detailItem.text} 
          image={detailItem.image} 
        />
      </div>
    ))}
  </div>
);

ContactDetails.propTypes = {
  container: PropTypes.string,
  detailItems: PropTypes.array
};

export default ContactDetails;