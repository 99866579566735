import React from "react";
import PropTypes from "prop-types";
import { Breadcrumb } from 'gatsby-plugin-breadcrumb';

import TextSection from '../components/utilities/TextSection';

import Layout from "../components/layout";
import SEO from "../components/seo";
import SubpageHero from '../components/subpages/Hero';
import ContactForm from '../components/forms/ContactForm';
import ContactDetails from '../components/contact/ContactDetails';

const ContactPage = ({ pageContext, location }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext;
  const customCrumbLabel = location.pathname.toLowerCase().replace('-', ' ').replace('/', '');

  return (
    <Layout>
      <SEO
        title="Contact"
      />
      <SubpageHero 
        title="Contact"
        breadcrumb={
        <Breadcrumb
          crumbs={crumbs}
          crumbSeparator=" | "
          crumbLabel={customCrumbLabel}
        />
        }
      />
      <ContactForm 
        title="Please complete this form for a Free Estimate or any enquiries on our services."
      />
      <ContactDetails 
        detailItems={[
          {
            title: "Email",
            text: (<div>
              <a className="block font-medium opacity-75" href="mailto:jamie@maibo.co.uk">jamie@maibo.co.uk</a>
            </div>),
            image: {
              alt: 'Email',
              filename: 'email.png'
            }
          },
          {
            title: "Phone",
            text: (<div>
              <a className="block font-medium opacity-75" href="tel:074888155894">07795 193177</a>
            </div>),
            image: {
              alt: 'Phone',
              filename: 'phone.png'
            }
          },
          {
            title: "Location",
            text: (<div className="font-medium opacity-75">
               Benfleet, <br/>
              Essex
            </div>),
            image: {
              alt: 'Location',
              filename: 'location.png'
            }
          }
        ]}
      />
      <div className="container mx-auto px-2 md:px-4 mb-16 md:mb-24">
        <div className="max-w-4xl">
          <TextSection 
            intro="You are welcome"
            text="I hope that you were happy with the service provided. Feedback via email is always appreciated. Customer satisfaction is the factor that drives the success of our business. We want to ensure that every customer benefits from our services and would always recommend us in the future. If you were unhappy with the level of service provided, then please contact us and we hope that we can resolve your complaint."
          />
        </div>
      </div>
    </Layout>
  );
}

ContactPage.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
};

export default ContactPage;
