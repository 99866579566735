import React from 'react';
import PropTypes from "prop-types";

import Image from '../utilities/image';

const ContactDetailItem = ({ title, text, image }) => (
  <div className="text-center">
    {image && <Image classes="w-16 mx-auto mb-2" alt={image.alt} filename={image.filename} />}
    {title && <h5 className="font-semibold text-xl mb-2">{title}</h5>}
    {text && text}
  </div>
);

ContactDetailItem.propTypes = {
  title: PropTypes.string,
  text: PropTypes.element,
  image: PropTypes.object,
};

export default ContactDetailItem;