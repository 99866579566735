import React from 'react';
import PropTypes from "prop-types";

import send from '../../images/send.svg';


const ContactForm = ({ container = "container mx-auto px-2 md:px-4", title }) => (
  <div className={`${container} mb-8 md:mb-16`}>
    {title && <h2 className="text-4xl md:text-5xl font-extrabold leading-tight mb-10">{title}</h2>}
    <form className="flex flex-wrap" name="contact" action="https://formspree.io/f/xleokzep" method="POST">
      <div className="flex-1 md:mr-4">
        <textarea
        name="message"
        placeholder="Message…"
        className="form-textarea w-full bg-gray-100 border-2 mb-3 md:mb-0"
        rows="11"
        />
      </div>
      <div className="w-full md:w-1/4">
        <input 
          type="text"
          name="subject"
          required
          placeholder="Subject"
          className="form-input w-full bg-gray-100 border-2 mb-4 py-4"
        />
        <input 
          type="text"
          name="name"
          required
          placeholder="Name"
          className="form-input w-full bg-gray-100 border-2 mb-4 py-4"
        />
        <input 
          type="email"
          name="_replyto"
          required
          placeholder="Email"
          className="form-input w-full bg-gray-100 border-2 mb-4 py-4"
        />
        <button type="submit" className="w-full py-4 bg-primary text-white flex flex-wrap items-center justify-center duration-300 hover:shadow-lg transform hover:-translate-y-1">
          Send
          <img className="ml-2" alt="send" src={send} />
        </button>
      </div>
    </form>
  </div>
);

ContactForm.propTypes = {
  container: PropTypes.string,
  title: PropTypes.string,
};

export default ContactForm;